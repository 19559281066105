
      @import "variables.scss";
      @import "placeholders.scss";
      @import "mixins.scss";
    
// Typography

// Weight

.ecTypographyRegular {
  font-weight: $font-weight-regular;
}

.ecTypographyBold {
  font-weight: $font-weight-bold;
}

// Utils

.ecTypographyNoWrap {
  white-space: nowrap;
}

// Variants

/// Display

.ecTypographyDisplayBig {
  font-size: $font-size-display-big;
  line-height: $font-line-height-display-big;
}

.ecTypographyDisplay {
  font-size: $font-size-display-default;
  line-height: $font-line-height-display-default;
}

.ecTypographyDisplaySmall {
  font-size: $font-size-display-small;
  line-height: $font-line-height-display-small;
}

/// Title

.ecTypographyTitleBig {
  font-size: $font-size-title-big;
  line-height: $font-line-height-title-big;
}

.ecTypographyTitle {
  font-size: $font-size-title-default;
  line-height: $font-line-height-title-default;
}

.ecTypographyTitleSmall {
  font-size: $font-size-title-small;
  line-height: $font-line-height-title-small;
}

/// Paragraph

.ecTypographyParagraphBig {
  font-size: $font-size-paragraph-big;
  line-height: $font-line-height-paragraph-big;
}

.ecTypographyParagraph {
  font-size: $font-size-paragraph-default;
  line-height: $font-line-height-paragraph-default;
}

.ecTypographyParagraphSmall {
  font-size: $font-size-paragraph-small;
  line-height: $font-line-height-paragraph-small;
}

/// Label

.ecTypographyLabel {
  font-size: $font-size-label-default;
  line-height: $font-line-height-label-default;
}

.ecTypographyLabelSmall {
  font-size: $font-size-label-small;
  line-height: $font-line-height-label-small;
}
