@use 'sass:math';

// Animation

$cubic-bezier: cubic-bezier(0.4, 0.2, 0, 1);

// Color options

$color-white: #fff;
$color-green-900: #001a17;
$color-green-800: #00332a;
$color-green-700: #004c40;
$color-green-600: #006655;
$color-green-500: #00806a;
$color-green-400: #26bfa6;
$color-green-300: #6cd9c7;
$color-green-200: #aaf2e6;
$color-green-100: #e5fffb;
$color-grey-900: #090a0d;
$color-grey-800: #212126;
$color-grey-700: #41434c;
$color-grey-600: #575966;
$color-grey-500: #737580;
$color-grey-400: #aaabb2;
$color-grey-300: #c0c2cc;
$color-grey-200: #dadce5;
$color-grey-100: #f0f1f7;
$color-pink-900: #431427;
$color-pink-800: #801a42;
$color-pink-700: #990f46;
$color-pink-600: #bf1357;
$color-pink-500: #e5005b;
$color-pink-400: #ec447f;
$color-pink-300: #fe98c1;
$color-pink-200: #febfd8;
$color-pink-100: #ffe5f0;
$color-purple-900: #2a084c;
$color-purple-800: #380a66;
$color-purple-700: #501980;
$color-purple-600: #5c1f99;
$color-purple-500: #7436b2;
$color-purple-400: #a860f0;
$color-purple-300: #c291f2;
$color-purple-200: #dfbfff;
$color-purple-100: #e8d7fa;
$color-red-900: #611414;
$color-red-800: #800d0d;
$color-red-700: #990000;
$color-red-600: #bf0000;
$color-red-500: #e50b0b;
$color-red-400: #ff6666;
$color-red-300: #ff9999;
$color-red-200: #ffbfbf;
$color-red-100: #fad7d7;
$color-yellow-900: #805500;
$color-yellow-800: #996600;
$color-yellow-700: #cc8800;
$color-yellow-600: #e59900;
$color-yellow-500: #ffc800;
$color-yellow-400: #ffdb59;
$color-yellow-300: #ffe380;
$color-yellow-200: #ffeca6;
$color-yellow-100: #fff3c7;

// Color definitions

$color-text: $color-grey-900;
$color-pink-gradient: linear-gradient(
  45deg,
  $color-pink-400 16%,
  $color-pink-500 62%
);
$color-purple-gradient: linear-gradient(
  45deg,
  $color-purple-400 16%,
  $color-purple-500 62%
);

// Space options

$space-xxxs: 4px;
$space-xxs: 8px;
$space-xs: 12px;
$space-s: 16px;
$space-m: 20px;
$space-l: 24px;
$space-xl: 28px;
$space-xxl: 32px;
$space-xxxl: 36px;
$space-xxxxl: 40px;
$space-g: 44px;
$space-xg: 48px;

// Space definitions

$space-x: $space-m;

// Size

$header-height: 60px;
$max-width-small: 768px;
$max-width: 1024px;
$max-width-big: 1200px;

// Typography

$font-family: 'Rubik', sans-serif;
$font-size-base: 16;
$font-size: 1rem;
$font-weight-regular: 400;
$font-weight-bold: 500;
$line-height: 1.5;

/// Display

$font-size-display-big: #{math.div(80, $font-size-base)}rem;
$font-size-display-default: #{math.div(60, $font-size-base)}rem;
$font-size-display-small: #{math.div(40, $font-size-base)}rem;
$font-line-height-display-big: math.div(96, 80);
$font-line-height-display-default: math.div(72, 60);
$font-line-height-display-small: math.div(48, 40);

/// Title

$font-size-title-big: #{math.div(32, $font-size-base)}rem;
$font-size-title-default: #{math.div(26, $font-size-base)}rem;
$font-size-title-small: #{math.div(20, $font-size-base)}rem;
$font-line-height-title-big: math.div(38, 32);
$font-line-height-title-default: math.div(32, 26);
$font-line-height-title-small: math.div(24, 20);

/// Paragraph

$font-size-paragraph-big: #{math.div(18, $font-size-base)}rem;
$font-size-paragraph-default: $font-size;
$font-size-paragraph-small: #{math.div(14, $font-size-base)}rem;
$font-line-height-paragraph-big: math.div(28, 18);
$font-line-height-paragraph-default: $line-height;
$font-line-height-paragraph-small: math.div(24, 14);

/// Label

$font-size-label-default: #{math.div(12, $font-size-base)}rem;
$font-size-label-small: #{math.div(10, $font-size-base)}rem;
$font-line-height-label-default: math.div(16, 12);
$font-line-height-label-small: math.div(16, 10);

// Border

$border-default-shorthand: 1px solid $color-grey-300;
$border-radius-small: 4px;
$border-radius-default: 8px;
$border-radius-big: 16px;

// Opacity

$opacity-dark: 0.95;
$opacity-default: 0.75;

// Shadow

$shadow-default-shorthand: 0px 0px 9px 2px rgb(38, 33, 36, 0.2);
$shadow-to-bottom-default-shorthand: 0px 4px 12px rgba(38, 33, 36, 0.2);
$shadow-to-top-default-shorthand: 0 -4px 12px rgba(38, 33, 36, 0.2);

// z-index

$z-index-listingsearchcontrols: 20;
$z-index-listingcta: 20;
$z-index-listingshare: 19;
$z-index-header: 30;
$z-index-navbar: 39;
$z-index-menu: 40;
$z-index-listingdetails: 49;
$z-index-modal: 50;
$z-index-loginmodal: 51;
$z-index-toast: 60;
