
      @import "variables.scss";
      @import "placeholders.scss";
      @import "mixins.scss";
    
// Variables

$footer-social-item-size: 20px;

// Footer About

.ecFooterAbout {
  display: grid;
  grid-gap: $space-xxs;

  @media screen and (min-width: 768px) {
    grid-gap: $space-xg;
    grid-template-columns: 1fr 1fr 1fr;
  }

  > div:nth-child(3) {
    justify-self: flex-end;
  }
}

.ecFooterSocial {
  display: flex;
  gap: $space-s;
  margin-bottom: $space-m;

  @media screen and (min-width: 768px) {
    margin-bottom: $space-xs;
  }
}

.ecFooterSocialItem {
  width: $footer-social-item-size;
  height: $footer-social-item-size;
}

.ecFooterSocialIcon {
  width: 100%;
  height: 100%;
}

.ecCopyrightCreciButton {
  font-size: $font-size-label-default;
  font-weight: $font-weight-bold;
}

.ecFooterAppStores {
  display: flex;
  flex-direction: row;
  gap: $space-s;
}
