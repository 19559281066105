
      @import "variables.scss";
      @import "placeholders.scss";
      @import "mixins.scss";
    
// Variables

$components-result-container-max-width-small: 320px;
$components-result-container-max-width-medium: 400px;
$components-result-container-max-width-big: 520px;
$components-result-iconwrapper-size-small: 40px;
$components-result-iconwrapper-size-medium: 60px;
$components-result-iconwrapper-size-big: 80px;
$components-result-imagewrapper-max-width-small: 240px;
$components-result-imagewrapper-max-width-medium: 280px;
$components-result-imagewrapper-max-width-big: 400px;

// Result

.ecResult {
  @extend %flexcenter;

  width: 100%;
}

.ecResultFullOnAPage {
  @extend %flexcenter;

  box-sizing: border-box;
  flex: 1;
  width: 100%;
  min-height: 100%;
  padding: $space-xg $space-x;
}

.ecResultContainer {
  display: grid;
  grid-gap: $space-m;
  width: 100%;
  max-width: $components-result-container-max-width-medium;
}

.ecResultContainerWidthSmall {
  max-width: $components-result-container-max-width-small;
}

.ecResultContainerWidthBig {
  max-width: $components-result-container-max-width-big;
}

.ecResultContainerSmall {
  grid-gap: $space-xs;
}

.ecResultContainerBig {
  grid-gap: $space-xl;
}

.ecResultIconWrapper {
  @extend %flexcenter;
  justify-self: center;
  width: $components-result-iconwrapper-size-medium;
  height: $components-result-iconwrapper-size-medium;
  border-radius: 50%;
  background-color: $color-grey-100;
}

.ecResultIconWrapperSmall {
  width: $components-result-iconwrapper-size-small;
  height: $components-result-iconwrapper-size-small;
}

.ecResultIconWrapperBig {
  width: $components-result-iconwrapper-size-big;
  height: $components-result-iconwrapper-size-big;
}

.ecResultIconWrapperSuccess {
  background-color: $color-green-100;
}

.ecResultIconWrapperError {
  background-color: $color-red-100;
}

.ecResultImageWrapper {
  width: 100%;
  max-width: $components-result-imagewrapper-max-width-medium;
  margin: auto;

  svg,
  img {
    display: block;
    width: 100%;
    height: auto;
    margin: auto;
  }
}

.ecResultImageWrapperSmall {
  max-width: $components-result-imagewrapper-max-width-small;
}

.ecResultImageWrapperBig {
  max-width: $components-result-imagewrapper-max-width-big;
}

.ecResultCopies {
  @extend %flexcolumn;
  gap: $space-xxs;
}

.ecResultCopies,
.ecResultChildren {
  text-align: center;
}

.ecResultButtons {
  display: grid;
  grid-gap: $space-s;
  text-align: center;

  &Big {
    @media screen and (min-width: $components-result-container-max-width-big) {
      grid-template-columns: 1fr 1fr;
    }
  }
}

.ecResultChildren {
  @extend %flexcolumn;
  align-items: center;
  width: 100%;
}
