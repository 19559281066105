%flexcolumn {
  display: flex;
  flex-direction: column;
}

%flexcenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

%fullmaxwidth {
  width: 100%;
  max-width: $max-width;
}

%fullmaxwidth-big {
  width: 100%;
  max-width: $max-width-big;
}

%search-container {
  width: 100%;
  padding: 0 $space-m;
  box-sizing: border-box;

  @media screen and (min-width: 1240px) {
    max-width: $max-width-big;
    padding: 0;
    margin: 0 auto;
  }
}

%notshow {
  position: fixed;
  top: -100vh;
  left: -100vw;
  pointer-events: none;
  user-select: none;
}
