
      @import "variables.scss";
      @import "placeholders.scss";
      @import "mixins.scss";
    
.ecImage {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;

  &Placeholder {
    background: $color-grey-100;
  }

  &FadeIn {
    opacity: 0;
    filter: blur(5px);
    transition: opacity 0.5s ease-out 0.2s, filter 0.7s ease-out;

    &Loaded {
      opacity: 1;
      filter: blur(0px);
    }
  }
}

.ecImageLoaderPlaceholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

// Border Radius

.ecImageBorderRadiusSmall {
  border-radius: $border-radius-small;
}

.ecImageBorderRadiusMedium {
  border-radius: $border-radius-default;
}

.ecImageBorderRadiusBig {
  border-radius: $border-radius-big;
}

.ecImageResponsive {
  max-width: 100%;
  height: 100%;
}
