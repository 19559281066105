// Display

@mixin flexcolumn {
  @include flexcolumn;
}

@mixin notshow {
  @include notshow;
}

// Size

@mixin fullmaxwidth {
  @include fullmaxwidth;
}

// Animation

@keyframes showScale {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@mixin animationShowscale {
  transform: scale(0);
  animation: showScale 0.6s $cubic-bezier 1s normal 1 forwards;
}

@mixin fadeInKeyframe($name, $from, $to) {
  @keyframes #{$name} {
    0% {
      opacity: $from;
    }
    100% {
      opacity: $to;
    }
  }
}

@mixin slideYKeyframe($name, $from, $to) {
  @keyframes #{$name} {
    0% {
      transform: translateY($from);
    }
    100% {
      transform: translateY($to);
    }
  }
}

@mixin slideXKeyframe($name, $from, $to) {
  @keyframes #{$name} {
    0% {
      transform: translateX($from);
    }
    100% {
      transform: translateX($to);
    }
  }
}

@mixin pulseKeyframe($name, $from: 1, $to: 0.85) {
  @keyframes #{$name} {
    0% {
      transform: scale($from);
    }
    100% {
      transform: scale($to);
    }
  }
}
