
      @import "variables.scss";
      @import "placeholders.scss";
      @import "mixins.scss";
    
// Variables

$components-header-logo-width-mobile: 96px;
$components-header-logo-height-mobile: 20px;
$components-header-logo-width-desktop: 102px;
$components-header-logo-height-desktop: 22px;

// HeaderLogo

.ecHeaderLogo {
  display: flex;
  align-items: center;
  height: 48px;
}

.ecHeaderLogoImg {
  display: block;
  width: $components-header-logo-width-mobile;
  height: $components-header-logo-height-mobile;

  @media screen and (min-width: 1024px) {
    width: $components-header-logo-width-desktop;
    height: $components-header-logo-height-desktop;
  }

  &White path {
    fill: $color-white;
    transition: fill 0.3s;

    @media screen and (min-width: 1024px) {
      fill: $color-pink-500;
    }
  }
}
