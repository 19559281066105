
      @import "variables.scss";
      @import "placeholders.scss";
      @import "mixins.scss";
    
.ecDefault {
  @extend %flexcolumn;

  align-items: center;
  width: 100%;
  min-height: 100%;
}

.ecMain {
  flex: 1;
  width: 100%;
}

.ecNavbarContainer {
  position: fixed;
  bottom: $space-m;
  z-index: 3;
}

.ecExtendedFooter {
  padding: $space-l $space-m 120px $space-m;
}
