
      @import "variables.scss";
      @import "placeholders.scss";
      @import "mixins.scss";
    
.ecNavbarContainer,
.ecNavbarStorybook {
  @media screen and (min-width: 768px) {
    display: none;
  }
  @extend %flexcenter;
  background-color: $color-grey-900;
  min-width: 305px;
  height: $space-xxxxl;
  justify-content: space-evenly;
  gap: $space-m;
  border-radius: $space-s;
  padding: $space-xs $space-xs $space-s;
  box-shadow: $shadow-to-bottom-default-shorthand;
}

.ecNavbarButton {
  padding: 0;
  color: $color-white;
  font-size: $font-size-label-default;
  background: none;
  border: none;
  @extend %flexcolumn;
  gap: $space-xxxs;
  span {
    gap: 0;
    align-items: center;
    @extend %flexcolumn;
  }
  &:hover {
    background-color: $color-grey-900;
  }
  &:focus,
  &.ecNavbarButtonActive {
    background-color: $color-grey-900;
    &::after {
      content: '';
      position: absolute;
      bottom: -$space-xxxs;
      display: block;
      width: $space-xl;
      border: 1px solid $color-white;
      border-radius: 1rem;
    }
  }
}
