
      @import "variables.scss";
      @import "placeholders.scss";
      @import "mixins.scss";
    
// Variables

$components-header-logo-width-mobile: 96px;
$components-header-logo-height-mobile: 20px;
$components-header-logo-width-desktop: 102px;
$components-header-logo-height-desktop: 22px;

// Header

.ecHeader {
  position: sticky;
  z-index: $z-index-header;
  top: 0;
  left: 0;
  flex: 0 0 $header-height;
  width: 100%;
  height: $header-height;
  background-color: $color-white;
  box-shadow: $shadow-to-bottom-default-shorthand;
  transition: all 0.7s $cubic-bezier;
}

.ecHeaderTransparent {
  background-color: transparent;
  box-shadow: none;
}

.ecHeaderNoShadow {
  box-shadow: none;
}

.ecHeaderBorderBottom {
  border-bottom: $border-default-shorthand;
}

.ecHeaderFixed {
  position: fixed;

  @media screen and (min-width: 1024px) {
    position: sticky;
  }
}

.ecHeaderWrapper {
  @extend %fullmaxwidth-big;
  display: flex;
  align-items: center;
  height: 100%;
  margin: auto;
  box-sizing: border-box;
  padding: 0 $space-m 0 $space-xxxs;
}

.ecHeaderWrapperNoMenuButton {
  padding: 0 $space-m;
}

.ecHeaderLeading {
  @extend %flexcenter;
  flex: 0 0 auto;
  gap: $space-xxs;
}

.ecHeaderCentered {
  @extend %flexcenter;
  flex: 1;
}

.ecHeaderTrailing {
  @extend %flexcenter;
  flex: 0 0 auto;
}

.ecHeaderLogo {
  display: flex;
  align-items: center;
  height: 48px;
}

.ecHeaderLogoImg {
  display: block;
  width: $components-header-logo-width-mobile;
  height: $components-header-logo-height-mobile;

  @media screen and (min-width: 1024px) {
    width: $components-header-logo-width-desktop;
    height: $components-header-logo-height-desktop;
  }
}

.ecHeaderLogoImgWhite path {
  fill: $color-white;
  transition: fill 0.3s;

  @media screen and (min-width: 1024px) {
    fill: $color-pink-500;
  }
}

.ecHeaderToggleButton {
  box-sizing: border-box;
  padding: 0 $space-s;
  height: 48px;
}
