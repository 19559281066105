
      @import "variables.scss";
      @import "placeholders.scss";
      @import "mixins.scss";
    
.ecTemplateErrorLinks {
  display: grid;
  grid-gap: $space-xxs;
  justify-content: center;
  text-align: center;
}
