
      @import "variables.scss";
      @import "placeholders.scss";
      @import "mixins.scss";
    
// Variables

$components-notification-width: calc(100% - $space-xxxxl);
$components-notification-max-width: 468px;

// Notification
@keyframes fade-in {
  0% {
    opacity: 0;
    bottom: -100px;
  }
  100% {
    opacity: 1;
    bottom: 0;
  }
}

.ecNotification {
  position: fixed;
  z-index: $z-index-toast;
  bottom: 0;
  left: 50%;
  transform: translate3d(-50%, -$space-x, 0);
  width: $components-notification-width;
  max-width: $components-notification-max-width;
  padding: $space-s;
  box-sizing: border-box;
  border: $border-default-shorthand;
  border-radius: $border-radius-default;
  background-color: $color-white;
  box-shadow: $shadow-default-shorthand;
  opacity: 0;

  &Visible {
    animation: fade-in 1s $cubic-bezier both;
  }

  &visibleTimeout {
    animation: fade-in 1s $cubic-bezier both 2s;
  }

  &LeftAlignment {
    @media screen and (min-width: 1024px) {
      left: $space-x;
      transform: translateY(-$space-x);
    }
  }

  &RightAlignment {
    @media screen and (min-width: 1024px) {
      left: initial;
      right: $space-x;
      transform: translateY(-$space-x);
    }
  }
}

.ecNotificationVisible {
  pointer-events: initial;
  user-select: initial;
}

.ecNotificationContent {
  font-size: $font-size-paragraph-small;
  line-height: $font-line-height-paragraph-small;
}

.ecNotificationButtons {
  display: flex;
  margin-top: $space-s;
}

.ecNotificationButton {
  flex: 1;
  margin-left: $space-s;
}

.ecNotificationButton:first-child {
  margin-left: 0;
}
