
      @import "variables.scss";
      @import "placeholders.scss";
      @import "mixins.scss";
    
.ecFooter {
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  padding: $space-l $space-m;
  background-color: $color-grey-100;
}

.ecFooterWithBar {
  padding-bottom: calc(48px * 2);
}

.ecFooterContainer {
  @extend %fullmaxwidth-big;
  display: grid;
  grid-gap: $space-xxl;

  > div:last-child {
    border-top: 1px solid $color-pink-500;
    padding-top: 32px;
  }
}

.ecFooterNav {
  display: grid;
  grid-gap: $space-xxl;
  width: 100%;
}

.ecFooterNavTitle {
  @extend %notshow;
}

.ecFooterNavList {
  display: grid;
  grid-gap: $space-xxs;
}

.ecFooterNavItem {
  display: grid;
  align-content: baseline;
  grid-gap: $space-xxs;
}

.ecFooterNavItemTitle {
  color: $color-grey-900;
}

.ecFooterNavLink {
  color: $color-grey-900;
  font-size: 0.875rem;
}

.ecFooterModalContainer {
  padding: $space-m;
}

.ecFooterModalList {
  margin-bottom: 0;

  dd {
    margin: 0;
    margin-bottom: $space-xs;
    margin-top: $space-xxs;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@media screen and (min-width: 768px) {
  .ecFooter {
    justify-content: center;
    padding-bottom: $space-xxl;
  }

  .ecFooterContainer {
    grid-template-columns: 1fr;
    width: 100%;
    max-width: $max-width-big;
  }

  .ecFooterNav {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: $space-xg;
  }
}

@media screen and (min-width: 1024px) {
  .ecFooterNav {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: $space-xg;
  }

  .ecFooterNavTwoColumns {
    grid-template-columns: repeat(2, 1fr);
  }
}
